import { Injectable } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpUrlEncodingCodec
} from "@angular/common/http";
import { GlobalSettingService } from "./global-setting.service";
import { catchError, map as rxJsMap, mergeMap, tap } from "rxjs/operators";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { assign, get, head, isNull, isObject, isUndefined, join, reduce } from "lodash-es";
import { DateUtil } from "./date-util";
import { STATUS_PROCESSING } from "./retry-helper";
import { ServiceUrl } from "./service-url";
import { ETagCache, ETaggedData } from "./e-tag-cache";
import { Logger } from "./logger/logger";
import { ApiResponseDataHeaders } from "../model/types/api-response";
import { Instrumentation } from "./instrumentation/instrumentation";
import { extractErrorString } from "./instrumentation/instrumentation-utility";
import { getGlobalConfig } from "../config";

export const SERVICE_CONTENT = "content";
export const SERVICE_METERMAN = "meterman";

export const DEFAULT_HEADERS = {
    "Cache-Control": "no-store",
    "Pragma": "no-cache"
};

const ACCEPT_HEADER_WEIGHTS = "application/json;q=0.9,*/*;q=0.8";
const SERVICE_ACCEPT_HEADERS = {
    v1: "application/vnd.englishcentral-v1+json",
    v2: "application/vnd.englishcentral-v2+json",
    v3: "application/vnd.englishcentral-v3+json",
    v4: "application/vnd.englishcentral-v4+json"
};

export const HEADER_HASMORE = "Hasmore";
export const DATA_HEADERS = ["Totalcount", "Limit", HEADER_HASMORE, "Start", "Link"];

export class ServiceHeaders {
    headers: {
        Totalcount: number;
        Limit: number;
        Hasmore: boolean;
        Start: number;
        Link: string;
    };
}

export const getHeaderDataFromResponse = (response: any): object => {
    return DATA_HEADERS.reduce((acc, headerKey) => {
        if (headerKey === HEADER_HASMORE) {
            return {...acc, [headerKey]: response?.headers?.get(headerKey) == "true"};
        }
        return {...acc, [headerKey]: response?.headers?.get(headerKey)};
    }, {});
};

export const generateHeaders = (version: string = ConnectionFactoryService.SERVICE_VERSION.v1, additionalHeaders: object = {}, addContentHeader: boolean = false): object => {
    if (!version) {
        return additionalHeaders;
    }

    let accept = SERVICE_ACCEPT_HEADERS[version];
    if (!accept) {
        return additionalHeaders;
    }

    if (addContentHeader) {
        return assign({}, {
            "Accept": `${accept},${ACCEPT_HEADER_WEIGHTS}`,
            "Content-Type": accept
        }, additionalHeaders);
    }

    return assign({}, {"Accept": `${accept},${ACCEPT_HEADER_WEIGHTS}`}, additionalHeaders);
};

export const extractResponseHeaderLink = ({Link}: ApiResponseDataHeaders): string | undefined => {
    return head(Link?.match(/\bhttps?:\/\/\S+/gi));
};

const getServiceUrl = (serviceName: string): string => {
    if (getGlobalConfig().useProxy) {
        return `/api/${serviceName}`;
    }
    return getGlobalConfig().restUrl?.[serviceName] ?? "";
};

const SERVICE_URL: ServiceUrl = {
    bridge: getServiceUrl("bridge"),
    tutor: getServiceUrl("tutor"),
    postoffice: getServiceUrl("postoffice"),
    reportcard: getServiceUrl("reportcard"),
    meterman: getServiceUrl("meterman"),
    harmony: getServiceUrl("harmony"),
    aeoncontent: "/api/aeoncontent",
    aeonidentity: "/api/aeonidentity",
    aeon: "/api/aeon",
    site: "/rest",
    base: "",
    content: getServiceUrl("content"),
    chat: getServiceUrl("chat"),
    socket: "api/socket",
    zendesk: "/zendesk",
    text2speech: getServiceUrl("text2speech")
};

@Injectable({
    providedIn: "root"
})
export class ConnectionFactoryService {
    static CACHE_LIFETIME = {
        bridge: 86400000,
        tutor: 60000,
        tutorAccount: 86400000,
        tutorRating: 259200000,
        postoffice: 180000,
        reportcard: 10000,
        chat: 86400000,
        site: 86400000,
        base: 86400000,
        commerce: 300000,
        content: 86400000,
        identity: 86400000,
        progress: 300000,
        eligibility: 5000,
        eligibilityReferral: 300000,
        classdata: 300000,
        meterman: 86400000,
        aeon: 600000,
        queryaccount: 604800000,
        outage: 900000,
        contentdialog: 21600000,
        zendesk: 86400000,
        references: 259200000,
        contentrecommendation: 600000
    };

    static SERVICE_VERSION = {
        v1: "v1",
        v2: "v2",
        v3: "v3",
        v4: "v4"
    };

    static METHOD_GET = "get";
    static METHOD_POST = "post";
    static METHOD_PUT = "put";
    static METHOD_DELETE = "delete";

    static RESPONSE_STATUS_CODE_200 = 200;
    static RESPONSE_STATUS_CODE_202 = 202;
    static RESPONSE_STATUS_CODE_204 = 204;
    static RESPONSE_STATUS_CODE_304 = 304;
    static RESPONSE_STATUS_CODE_404 = 404;
    static RESPONSE_STATUS_CODE_429 = 429;
    static RESPONSE_STATUS_BAD_REQUEST = 400;
    static RESPONSE_STATUS_UNAUTHORIZED = 401;

    private static serverDate: string;

    private useFullPath: boolean = false;
    private siteLanguage: string = "";

    constructor(private http: HttpClient, private globalSettingService: GlobalSettingService) {
        this.initializeSettings();
        this.globalSettingService.subscribe(GlobalSettingService.EVENT_SETTINGS_CHANGE, () => {
            this.initializeSettings();
        });
    }

    private initializeSettings(): void {
        this.siteLanguage = this.globalSettingService.get("lang");
        this.useFullPath = this.globalSettingService.get("useFullPath") || false;
    }

    getAuthorizationHeader(): string {
        const jwtToken = this.globalSettingService.getJwtToken();
        if (jwtToken) {
            return `JWT ${jwtToken}`;
        }

        const sdkToken = this.globalSettingService.getSdkToken();
        if (sdkToken) {
            return sdkToken;
        }

        return "";
    }

    getSiteLanguage(): string {
        return this.siteLanguage;
    }

    service<K extends keyof ServiceUrl>(connectionName?: K,
                                        useFullPath: boolean = false,
                                        normalizeEmptyResponseStatusCodes: boolean = true): BaseConnection {
        let baseServiceUrl = SERVICE_URL[connectionName] ?? SERVICE_URL.base;
        const ecBackendUrl = this.globalSettingService.getLanguageDomain(this.globalSettingService.getLanguage());

        if (!baseServiceUrl.includes("https://") && (this.useFullPath || useFullPath)) {
            baseServiceUrl = `${ecBackendUrl}${baseServiceUrl}`;
        }

        return new BaseConnection(
            this.http,
            baseServiceUrl,
            ecBackendUrl,
            this.getAuthorizationHeader(),
            this.getSiteLanguage(),
            normalizeEmptyResponseStatusCodes
        ).setCmsMode(this.globalSettingService.isCmsMode());
    }

    create(baseServiceUrl: string = "", normalizeEmptyResponseStatusCodes: boolean = true) {
        return new BaseConnection(
            this.http,
            baseServiceUrl,
            this.globalSettingService.getLanguageDomain(this.globalSettingService.getLanguage()),
            this.getAuthorizationHeader(),
            this.getSiteLanguage(),
            normalizeEmptyResponseStatusCodes
        ).setCmsMode(this.globalSettingService.isCmsMode());
    }

    private getHealthcheckUrl(): string {
        return (this.useFullPath ? this.globalSettingService.getLanguageDomain(this.globalSettingService.getLanguage()) : "") + "/api/bridge/healthcheck";
    }

    static setServerDate(serverDate: string): void {
        ConnectionFactoryService.serverDate = serverDate;

        let serverTimeDifference = Date.parse(serverDate) - Date.now();
        DateUtil.setServerTimeDifference(serverTimeDifference);
    }

    static getServerDate(): string | undefined {
        return ConnectionFactoryService.serverDate;
    }

    generateServerDate(): Observable<string> {
        if (ConnectionFactoryService.getServerDate()) {
            return of(ConnectionFactoryService.getServerDate());
        }
        return this.http
            .get(this.getHealthcheckUrl(), {observe: "response"})
            .pipe(
                rxJsMap((response: HttpResponse<any>) => {
                    if (!response || !response.headers) {
                        return "";
                    }
                    ConnectionFactoryService.setServerDate(response.headers.get("date"));
                    return ConnectionFactoryService.getServerDate();
                })
            );
    }
}

export class BaseConnection {
    private path: string = "";
    private fullResponse: boolean = false;
    private appendHeaderData: boolean = false;
    private refresh: boolean = false;
    private eTagCache = new ETagCache<any>("eTag20250325");
    private logger = new Logger();
    private isCms: boolean = false;
    private jwtRetries = 0;

    constructor(private http: HttpClient,
                private serviceUrl: string,
                private ecBackendUrl?: string,
                private authorization?: string,
                private siteLanguage?: string,
                private normalizeEmptyResponseStatusCodes: boolean = true) {
    }

    setPath(path: string): BaseConnection {
        this.path = path;
        return this;
    }

    setFullResponse(fullResponse: boolean): BaseConnection {
        this.fullResponse = fullResponse;
        return this;
    }

    setAppendHeaderData(enabled: boolean): BaseConnection {
        this.appendHeaderData = enabled;
        return this;
    }

    setRefresh(refresh: boolean): BaseConnection {
        this.refresh = refresh;
        return this;
    }

    setCmsMode(state: boolean): BaseConnection {
        this.isCms = state;
        return this;
    }

    getUrl(): string {
        return this.serviceUrl + this.path;
    }

    get(query?: object,
        postBody: any = "",
        version?: string,
        additionalOptions?: object,
        additionalHeaders: object = {}): Observable<any> {
        return this.request(
            ConnectionFactoryService.METHOD_GET,
            this.getUrl(),
            query,
            postBody,
            generateHeaders(version, additionalHeaders, !(postBody instanceof FormData)),
            additionalOptions
        );
    }

    post(query?: object,
         postBody: any = "",
         version?: string,
         additionalOptions?: object,
         additionalHeaders: object = {}): Observable<any> {
        return this.request(
            ConnectionFactoryService.METHOD_POST,
            this.getUrl(),
            query,
            postBody,
            generateHeaders(version, additionalHeaders, !(postBody instanceof FormData)),
            additionalOptions
        );
    }

    put(query?: object,
        postBody: any = "",
        version?: string,
        additionalOptions?: object,
        additionalHeaders: object = {}): Observable<any> {
        return this.request(
            ConnectionFactoryService.METHOD_PUT,
            this.getUrl(),
            query,
            postBody,
            generateHeaders(version, additionalHeaders, !(postBody instanceof FormData)),
            additionalOptions
        );
    }

    delete(query?: object,
           postBody: any = "",
           version?: string,
           additionalOptions?: object,
           additionalHeaders: object = {}): Observable<any> {
        return this.request(
            ConnectionFactoryService.METHOD_DELETE,
            this.getUrl(),
            query,
            postBody,
            generateHeaders(version, additionalHeaders, !(postBody instanceof FormData)),
            additionalOptions
        );
    }

    ajax(additionalSettings: object, queryParams?: object): Observable<AjaxResponse<any>> {
        let settings = assign({
            url: this.getUrl() + this.jsonToQueryString(queryParams)
        }, additionalSettings);

        return ajax(settings);
    }

    private jsonToQueryString(json?: object): string {
        if (!json) {
            return "";
        }

        let queryString = join(reduce(json, (acc: string[], value: any, key) => {
            if (isUndefined(value) || isNull(value)) {
                return acc;
            }
            const encodedValue = encodeURIComponent(value);
            if (!encodedValue) {
                return acc;
            }

            acc.push(`${encodeURIComponent(key)}=${encodedValue}`);
            return acc;
        }, []), "&");

        return queryString ? `?${queryString}` : "";
    }

    private isFallbackResponse(response: HttpResponse<any>): boolean {
        return response?.body?.fallback === true;
    }

    private isProcessingNotCompletedResponse(response: HttpResponse<any>): boolean {
        return response?.status === STATUS_PROCESSING;
    }

    private generateCacheKey(method: string,
                             url: string,
                             query?: object,
                             postBody?: any,
                             lang?: string): Record<string, any> {
        return {
            method: method,
            url: url,
            query: query,
            post: postBody,
            lang: lang
        };
    }

    private request(method: string,
                    url: string,
                    query?: object,
                    postBody?: any,
                    additionalHeaders?: object,
                    additionalOptions?: object): Observable<any> {
        if (this.isCms && !isUndefined(query)) {
            query["rnd"] = Date.now();
        }
        if (this.refresh) {
            return this.processRequest(method, url, query, postBody, additionalHeaders, additionalOptions);
        }

        const cacheKey = this.generateCacheKey(method, url, query, postBody, this.siteLanguage);
        this.logger.log("eTag cacheKey", cacheKey);
        return this.eTagCache
            .getCache(cacheKey)
            .pipe(
                catchError(e => {
                    this.logger.log("connection factory - local storage error");
                    return of(undefined);
                }),
                mergeMap(cache => {
                    if (cache && !cache.revalidate) {
                        return of(cache.data);
                    }
                    return this.processRequest(method, url, query, postBody, additionalHeaders, additionalOptions, cache);
                })
            );
    }

    private parseMaxAge(cacheControlHeader?: string): number {
        if (!cacheControlHeader) {
            return 0;
        }

        const SECONDS_TO_MS = 1000;
        const matches = cacheControlHeader.match(/max-age=(\d+)/);
        return (matches ? parseInt(matches[1], 10) : 0) * SECONDS_TO_MS;
    }

    private processRequest(method: string,
                           url: string,
                           query?: object,
                           postBody?: any,
                           additionalHeaders?: object,
                           additionalOptions?: object,
                           cache?: ETaggedData<any>): Observable<any> {
        let isFormData = postBody instanceof FormData;
        let body = (isObject(postBody) && !isFormData)
            ? JSON.stringify(postBody)
            : (postBody || "");
        let contentType = isFormData ? "multipart/form-data" : "application/json";
        let headers = new HttpHeaders(assign({"Content-Type": contentType}, additionalHeaders));
        if (this.authorization) {
            headers = headers.append("Authorization", this.authorization);
        }
        if (this.shouldRevalidateETag(cache)) {
            headers = headers.append("If-None-Match", cache.eTag);
        }
        if (this.siteLanguage && !additionalHeaders?.["EC-Site-Language"]) {
            headers = headers.append("EC-Site-Language", this.siteLanguage);
        }

        let params = new HttpParams({encoder: new CustomUrlEncodingCodec()});
        params = query ? reduce(query, (acc: HttpParams, value, key) => {
            if (!isUndefined(value) && !isNull(value)) {
                return acc.set(key, value);
            }
            return acc;
        }, params) : undefined;

        let options = assign({
            headers: headers,
            params: params,
            withCredentials: true
        }, additionalOptions);
        options["observe"] = "response"; // messed up override typings on angular's side

        let response$: Observable<any>;

        switch (method) {
            case ConnectionFactoryService.METHOD_GET:
                response$ = this.http.get(url, options);
                break;
            case ConnectionFactoryService.METHOD_POST:
                response$ = this.http.post(url, body, options);
                break;
            case ConnectionFactoryService.METHOD_PUT:
                response$ = this.http.put(url, body, options);
                break;
            case ConnectionFactoryService.METHOD_DELETE:
                response$ = this.http.delete(url, {...options, ...{body: body}});
                break;
            default:
                throw new Error("Invalid HTTP method");
        }

        const cacheKey = this.generateCacheKey(method, url, query, postBody);

        return response$.pipe(
            catchError((error: HttpErrorResponse) => this.handleError(error)),
            catchError((error: HttpErrorResponse) => {
                const MAX_RETRIES = 1;
                const AUTH_ERRORS = [
                    ConnectionFactoryService.RESPONSE_STATUS_UNAUTHORIZED,
                    ConnectionFactoryService.RESPONSE_STATUS_BAD_REQUEST
                ];
                if (AUTH_ERRORS.includes(error?.status)) {
                    if (this.jwtRetries < MAX_RETRIES) {
                        return this.refreshJwt().pipe(
                            switchMap(() => {
                                this.jwtRetries += 1;
                                return this.processRequest(
                                    method,
                                    url,
                                    query,
                                    postBody,
                                    additionalHeaders,
                                    additionalOptions,
                                    cache
                                );
                            })
                        );
                    }

                    Instrumentation.sendEvent("401-error", {
                        referrer: window.location.href,
                        requestUrl: error?.url,
                        message: get(error, "message"),
                        errorKey: get(error, "error.key"),
                        errorMessage: extractErrorString(get(error, "error"))
                    });
                }

                throw error;
            }),
            tap((response: HttpResponse<any>) => {
                if (!ConnectionFactoryService.getServerDate() && response?.headers?.get("Api-Date")) {
                    ConnectionFactoryService.setServerDate(response.headers.get("Api-Date"));
                }

                if (!response
                    || !response.headers.get("ETag")
                    || this.isFallbackResponse(response)
                    || this.isProcessingNotCompletedResponse(response)
                    || response?.status == ConnectionFactoryService.RESPONSE_STATUS_CODE_304) {
                    return;
                }

                this.setETagCache(
                    cacheKey,
                    response?.body,
                    response.headers.get("ETag"),
                    this.parseMaxAge(response.headers.get("Cache-Control"))
                );
            }),
            mergeMap((response: HttpResponse<any>) => {
                if (this.fullResponse) {
                    return of(response);
                }

                if (this.isFallbackResponse(response) || this.isProcessingNotCompletedResponse(response)) {
                    throw response;
                }

                if (this.shouldRevalidateETag(cache) && response?.status == ConnectionFactoryService.RESPONSE_STATUS_CODE_304) {
                    this.setETagCache(
                        cacheKey,
                        cache.data,
                        response.headers.get("ETag"),
                        this.parseMaxAge(response.headers.get("Cache-Control"))
                    );
                    return of(cache.data);
                }

                const responseBody = response?.body;
                if (this.appendHeaderData && isObject(responseBody)) {
                    return of({...responseBody, headers: getHeaderDataFromResponse(response)});
                }

                return of(responseBody);
            })
        );
    }

    private shouldRevalidateETag(cache: ETaggedData<any>): boolean {
        return cache?.revalidate && !!cache?.eTag;
    }

    private setETagCache(cacheKey: object, data: any, eTag?: string, maxAge?: number): void {
        if (!maxAge || maxAge < 0) {
            return;
        }

        // BC-92136, BC-92137 set max lifetime to 12h
        const HOUR_12 = 43200000;
        if (maxAge > HOUR_12) {
            maxAge = HOUR_12;
        }

        this.eTagCache.setValue(
            cacheKey,
            data,
            maxAge,
            eTag
        );
    }

    private refreshJwt(): Observable<any> {
        const ecBackendUrl = (<any>window)?.ECSDK ? this.ecBackendUrl : "";
        return this.http.get(`${ecBackendUrl}/rest/identity/refreshjwt`)
            .pipe(
                tap((response) => {
                    if (response?.jwt) {
                        (<any>window).EC_JWT = response.jwt;
                        this.authorization = `JWT ${response.jwt}`;
                    }
                })
            );
    }

    private handleError(error: HttpErrorResponse) {
        const ignoreEmptyStatusCodes = [
            ConnectionFactoryService.RESPONSE_STATUS_CODE_429,
            ConnectionFactoryService.RESPONSE_STATUS_CODE_204,
            ConnectionFactoryService.RESPONSE_STATUS_CODE_304,
            ConnectionFactoryService.RESPONSE_STATUS_CODE_202
        ];

        if (!this.isCms) {
            ignoreEmptyStatusCodes.push(ConnectionFactoryService.RESPONSE_STATUS_CODE_404);
        }

        if (this.normalizeEmptyResponseStatusCodes && ignoreEmptyStatusCodes.includes(error?.status)) {
            return of(error);
        }

        throw error;
    }
}

class CustomUrlEncodingCodec extends HttpUrlEncodingCodec {
    encodeKey(key: string): string {
        return this.standardEncoding(key);
    }

    encodeValue(value: string): string {
        return this.standardEncoding(value);
    }

    private standardEncoding(v: string): string {
        return encodeURIComponent(v)
            .replace(/%40/gi, "@")
            .replace(/%3A/gi, ":")
            .replace(/%24/gi, "$")
            .replace(/%2C/gi, ",")
            .replace(/%3B/gi, ";")
            .replace(/%3D/gi, "=")
            .replace(/%3F/gi, "?")
            .replace(/%2F/gi, "/");
    }
}
